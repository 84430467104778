/**
 * Runner of handlers
 */

const findElementWithHandler = elm => {
  if (!elm || elm.tagName === 'HTML') {
    return null;
  }
  if (elm.getAttribute('data-handler')) {
    return elm;
  }
  if (!elm.parentNode || elm.parentNode.nodeName === 'BODY') {
    return false;
  }
  return findElementWithHandler(elm.parentNode);
};

const handle = (handlers) => {
  if (!handlers) {
    throw new Error('Nothing to handle');
  }

  document.documentElement.addEventListener('click', event => {
    const trigger = findElementWithHandler(event.srcElement);

    if (!trigger) {
      return;
    }

    const handler = trigger.getAttribute('data-handler');

    if (!handler) {
      // nothing to do
      return;
    }
    if (event.srcElement.tagName === 'A' && (event.metaKey || event.ctrlKey || event.shiftKey)) {
      // honour default behaviour on <a>s when using modifier keys when clicking
      // meta / ctrl open in new tab
      // shift opens in a new window
      return;
    }
    if (typeof handlers[handler] === 'function') {
      handlers[handler](trigger, event);
    } else if (console && console.log) {
      console.log('unknown handler \'%s\' on %o', handler, trigger);
    }
  });
};

export { handle };
