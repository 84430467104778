/**
 * Runner of enhancers
 */

const y = window.yQuery;

const ATTRIBUTE = 'data-enhancer';

const enhance = (enhancers) => {
  if (!enhancers) {
    return;
  }

  const enhancerElms = y.many(`[${ATTRIBUTE}]`);
  enhancerElms.forEach((elm) => {
    const enhancerCollection = elm.getAttribute(ATTRIBUTE).split(', ');
    enhancerCollection.forEach((enhancer) => {
      if (typeof enhancers[enhancer] === 'function') {
        enhancers[enhancer](elm);
      } else if (window.console && typeof console.error === 'function') {
        console.error('Non-existing enhancer: "%s" on %o', enhancer, elm);
      }
    });
  });
};

export { enhance };
