export const enhancer = el => {
  const open = el.querySelectorAll('.open-modal');

  open.forEach(e => {
    e.addEventListener('click', (event) => {
      event.preventDefault();

      const { modalName } = event.target.dataset;
      const modal = el.querySelector(`#${modalName}-modal`);
      const close = modal.querySelector('.close');

      modal.style.display = "flex";
      window.addEventListener('click', evt => {
        if (evt.target === modal || evt.target === close) {
          modal.style.display = "none";
        }
      });
    });
  });
};
