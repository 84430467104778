import { enhance } from "../modules/enhance";
import { handle } from "../modules/handle";

import { enhancer as applyRadio } from "../modules/apply-radio";
import { enhancer as multipleFileInput } from "../modules/multiple-file-input";
import { enhancer as toggleInput } from "../modules/toggle-input";
import { enhancer as mirrorInput } from "../modules/mirror-input";
import { enhancer as modal } from "../modules/modal";
import { enhancer as ajaxLoad } from "../modules/ajax-load";
import { enhancer as changeUpdate } from "../modules/change-update";
import { enhancer as showMore } from "../modules/show-more";
import { enhancer as accordionImages } from "../modules/accordion-images";

window.jQuery = require('jquery');
require('bootstrap');

export const ENHANCERS = {
  accordionImages,
  ajaxLoad,
  showMore,
  changeUpdate,
  applyRadio,
  multipleFileInput,
  toggleInput,
  mirrorInput,
  modal,
};

export const HANDLERS = {};

function main() {
  enhance(ENHANCERS);
  handle(HANDLERS);
}

window.yQuery(main);
