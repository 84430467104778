export const enhancer = (el) => {
  if (!el) {
    return;
  }

  jQuery.ajax({
    type: 'GET',
    url: el.dataset.url,
    success: (response) => {
      el.innerHTML = response;
    },
  });
};
