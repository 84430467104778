export const enhancer = el => {
  const input = el.querySelector('.mirror-input');
  const labels = el.querySelectorAll('.mirror-label');

  input.addEventListener('change', () => {
    labels.forEach(label => {
      label.innerHTML = input.value;
    });
  });
};
