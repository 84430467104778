export const enhancer = (el) => {
  const input = el.querySelector('.valueSelector');
  const target = el.querySelector('.targetDiv');
  let fader;
  let loader1;
  let loader2;

  function loadValue({ value }) {
    jQuery.ajax({
      type: 'GET',
      url: `${el.dataset.url}/${value}?screenWidth=${jQuery(window).width()}`,
      success: (response) => {
        target.classList.remove('fade');
        target.innerHTML = response;
      },
    });
  }

  function loadRandom() {
    jQuery.ajax({
      type: 'GET',
      url: `${el.dataset.url}/random?screenWidth=${jQuery(window).width()}`,
      success: (response) => {
        target.innerHTML = response;
        target.classList.remove('fade');
      },
    });

    fader = setTimeout(() => {
      target.classList.add('fade');

      loader1 = setTimeout(() => {
        loadRandom();
      }, 500);
    }, 9500);
  }

  input.addEventListener('change', ({ currentTarget }) => {
    target.classList.add('fade');

    clearTimeout(fader);
    clearTimeout(loader1);
    clearTimeout(loader2);

    loader2 = setTimeout(() => {
      input.value === 'random' ? loadRandom() : loadValue(currentTarget);
    }, 500);
  });

  loadRandom();
};
