export const enhancer = el => {
  const fileInput = el.querySelector("#f_input");
  const fileContainer = el.querySelector("#file-container");

  const updateButtonText = () => {
    const text = el.querySelector('.button-text');
    const files = el.querySelectorAll("#file-container");

    if (files.length > 1) {
      text.innerHTML = 'Add more files';
    } else {
      text.innerHTML = 'Add files';
    }
  };

  function addFileContainer({ target }) {
    const fileCount = target.files.length;
    const fileName = (fileCount > 1)
      ? `${fileCount} files`
      : target.files[0].name;

    const inputWithValue = target.cloneNode(false);
    inputWithValue.id = '';
    fileInput.value = '';

    const newFileContainer = fileContainer.cloneNode(true);
    newFileContainer.classList.remove("d-no");
    newFileContainer.querySelector('.file-name').innerHTML = fileName;
    newFileContainer.appendChild(inputWithValue);

    const removeButton = newFileContainer.querySelector('.remove-button');
    removeButton.addEventListener("click", () => {
      newFileContainer.remove();
      updateButtonText();
    });

    el.insertBefore(newFileContainer, fileContainer);
    updateButtonText();
  }

  fileInput.addEventListener("change", addFileContainer);
};
