export const enhancer = el => {
  const elements = el.querySelectorAll(".radio");

  const selectElement = (clickedElement) => {
    elements.forEach(element => {
      element.classList.remove("selected");
      element.checked = false;
    });

    clickedElement.classList.add("selected");
    clickedElement.checked = true;

    const valueInput = el.querySelector("#valueInput");
    if (valueInput) {
      valueInput.value = clickedElement.value;
    }
  };

  elements.forEach((element) => {
    element.addEventListener("click", (e) => selectElement(e.currentTarget));
  });
};
