export const enhancer = (el) => {
  const headers = el.querySelectorAll('.accordion-heading');
  const images = el.querySelectorAll('.accordion-image');

  headers.forEach(heading => {
    heading.addEventListener('click', (e) => {
      images.forEach(image => {
        if (image.dataset.item === e.currentTarget.dataset.item) {
          image.classList.add('show');
        } else {
          image.classList.remove('show');
        }
      })
    })
  });
};
