export const enhancer = el => {
  const nodes = el.querySelectorAll('.question');
  const moreButton = el.querySelector('.show-more');
  const lessButton = el.querySelector('.show-less');
  const initialAmount = parseInt(el.dataset.initialAmount, 10);
  const modifyAmount = parseInt(el.dataset.modifyAmount, 10);

  function hideInitialNodes() {
    for (let i = 0; i < nodes.length - initialAmount; i += 1) {
      nodes[nodes.length - 1 - i].classList.add('hidden');
    }
  }

  function setButtonVisibility(button, otherButton, nodeList, margin = 0) {
    otherButton.classList.remove('hidden');
    if (nodeList.length <= modifyAmount + margin) {
      button.classList.add('hidden');
    }
  }

  function getCap(nodeList, margin = 0){
    const value = modifyAmount + margin;
    return nodeList.length >= value
      ? 0
      : value - nodeList.length;
  }

  hideInitialNodes();

  moreButton.addEventListener('click', () => {
    const invisibleNodes = el.querySelectorAll('.hidden');
    const showCap = getCap(invisibleNodes);
    setButtonVisibility(moreButton, lessButton, invisibleNodes);

    for (let i = 0; i < modifyAmount - showCap; i += 1) {
      invisibleNodes[i].classList.remove('hidden');
    }
  });

  lessButton.addEventListener('click', () => {
    const visibleNodes = el.querySelectorAll('.question:not(.hidden)');
    const hideCap = getCap(visibleNodes, initialAmount);
    setButtonVisibility(lessButton, moreButton, visibleNodes, initialAmount);

    for (let i = 0; i < modifyAmount - hideCap; i += 1) {
      visibleNodes[visibleNodes.length - 1 - i].classList.add('hidden');
    }
  });
};
