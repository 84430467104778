export const enhancer = el => {
  const input = el.querySelector('.toggle-input');
  const divs = el.querySelectorAll('.toggle-div');

  input.addEventListener('change', () => {
    if (input.value) {
      divs.forEach(div => {
        div.classList.remove('d-no');
        div.classList.add('d-fl');
      });
    } else {
      divs.forEach(div => {
        div.classList.remove('d-fl');
        div.classList.add('d-no');
      });
    }
  });
};
